<template lang="pug">
  v-card
    v-card-head
      v-card-head-label
        h2.modal-title {{ 'pages.administration.edit_position' | translate }}
      v-card-head-toolbar
        v-btn(
          icon
          gray
          @click="onClose") x
    v-card-content
      input.form-control(
        type="text"
        v-model="name"
        :placeholder="'pages.administration.position_name' | translate")
    v-card-actions
      v-btn(
        text
        @click="onClose") {{ 'ui.buttons.cancel' | translate }}
      v-btn(
        @click="onApply") {{ 'ui.buttons.save' | translate }}

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CreateModal',

  mounted () {
    this.name = this.currentPosition.name
  },

  data: () => ({
    name: ''
  }),

  methods: {
    ...mapActions('positions', [
      'updatePosition'
    ]),

    onApply () {
      this.currentPosition.name = this.name
      this.updatePosition(this.currentPosition)
      this.onClose()
    },

    onClose () {
      this.$emit('close')
    }
  },

  computed: {
    ...mapGetters('positions', [
      'currentPosition'
    ])
  }
}
</script>

<style lang="scss">
</style>
